import React from "react";
import js from "react-syntax-highlighter/languages/hljs/javascript";
import php from "react-syntax-highlighter/languages/hljs/php";
import ruby from "react-syntax-highlighter/languages/hljs/ruby";
import python from "react-syntax-highlighter/languages/hljs/python";
import xml from "react-syntax-highlighter/languages/hljs/xml";
import css from "react-syntax-highlighter/languages/hljs/css";
import md from "react-syntax-highlighter/languages/hljs/markdown";
import SyntaxHighlighter, {
	registerLanguage,
} from "react-syntax-highlighter/light";

// import codeTheme from "react-syntax-highlighter/styles/hljs/tomorrow-night-eighties";

const theme = {
	hljs: {
		display: "block",
		overflowX: "auto",
		padding: "0.5em",
		background: "#444",
		color: "#fff",
	},
	"hljs-keyword": {
		color: "hsl(204, 100%, 68%)",
		fontWeight: "bold",
	},
	"hljs-selector-tag": {
		color: "hsl(0, 0%, 100%)",
		fontWeight: "bold",
	},
	"hljs-literal": {
		color: "hsl(0, 0%, 100%)",
		fontWeight: "bold",
	},
	"hljs-section": {
		color: "hsl(0, 0%, 100%)",
		fontWeight: "bold",
	},
	"hljs-link": {
		color: "hsl(0, 0%, 100%)",
	},
	"hljs-subst": {
		color: "#ddd",
	},
	"hljs-string": {
		color: "hsla(204, 45%, 88%, 0.8)",
	},
	"hljs-title": {
		color: "hsl(204, 45%, 88%)",
		fontWeight: "bold",
	},
	"hljs-name": {
		color: "hsl(204, 45%, 88%)",
		fontWeight: "bold",
	},
	"hljs-type": {
		color: "hsl(204, 45%, 88%)",
		fontWeight: "bold",
	},
	"hljs-attribute": {
		color: "hsl(204, 45%, 88%)",
	},
	"hljs-symbol": {
		color: "hsl(204, 45%, 88%)",
	},
	"hljs-bullet": {
		color: "hsl(204, 45%, 88%)",
	},
	"hljs-built_in": {
		color: "hsl(204, 45%, 88%)",
	},
	"hljs-addition": {
		color: "hsl(204, 45%, 88%)",
	},
	"hljs-variable": {
		color: "hsl(204, 45%, 88%)",
	},
	"hljs-template-tag": {
		color: "hsl(204, 45%, 88%)",
	},
	"hljs-template-variable": {
		color: "hsl(204, 45%, 88%)",
	},
	"hljs-comment": {
		color: "#777",
	},
	"hljs-quote": {
		color: "#777",
	},
	"hljs-deletion": {
		color: "#777",
	},
	"hljs-meta": {
		color: "#777",
	},
	"hljs-doctag": {
		fontWeight: "bold",
	},
	"hljs-strong": {
		fontWeight: "bold",
	},
	"hljs-emphasis": {
		fontStyle: "italic",
	},
};

registerLanguage("javascript", js);
registerLanguage("js", js);
registerLanguage("xml", xml);
registerLanguage("php", php);
registerLanguage("ruby", ruby);
registerLanguage("python", python);
registerLanguage("css", css);
registerLanguage("md", md);

// USAGE:
//
// <CodePreviewBox>
// 	<CodePreviewTab label="Node.js">
// 		<CodePreview>JS code...</CodePreview>
// 	</CodePreviewTab>
//
// 	<CodePreviewTab label="Ruby">
// 		<CodePreview>ruby code...</CodePreview>
// 	</CodePreviewTab>
// </CodePreviewBox>

export function CodePreview({ language = "text", children }) {
	return (
		<SyntaxHighlighter
			language={language}
			style={theme}
			className="codePreviewDocs">
			{children.trim()}
		</SyntaxHighlighter>
	);
}

export function CodePreviewTabsContainer({ children }) {
	return <div className="codePreviewTabsContainer">{children}</div>;
}

export function CodePreviewTab({ label, children }) {
	return <>{children}</>;
}

export function CodePreviewBox({ children }) {
	const [activeTab, setActiveTab] = React.useState(0);

	const tabs = React.Children.map(children, function({ props }) {
		return props.label;
	});

	return (
		<div>
			<CodePreviewTabsContainer>
				{tabs.map((label, index) => {
					return (
						<button
							key={label}
							className={index === activeTab ? "is-active " : ""}
							onClick={() => setActiveTab(index)}>
							{label}
						</button>
					);
				})}
			</CodePreviewTabsContainer>

			{children[activeTab]}
		</div>
	);
}

export function PreviewTab({ label, children }) {
	return <>{children}</>;
}

export function PreviewBox({ children }) {
	const [activeTab, setActiveTab] = React.useState(0);

	const tabs = React.Children.map(children, function({ props }) {
		return props.label;
	});

	return (
		<div className="tabsContainer">
			<div className="tabNav">
				{tabs.map((label, index) => {
					return (
						<button
							key={label}
							className={index === activeTab ? "is-active " : ""}
							onClick={() => setActiveTab(index)}>
							{label}
						</button>
					);
				})}
			</div>

			<div className="tabContent">
				{React.Children.toArray(children)[activeTab]}
			</div>
		</div>
	);
}
