import React from "react";

export default function EmailTemplateSmallBox({ title, href, src }) {
	return (
		<a
			className="emailTemplateContainerSmall box-whiteWithBorder mb-20"
			href={href}>
			<div className="emailTemplateImageWrapperSmall">
				<img
					src={src}
					loading="lazy"
					alt={title}
					className="emailTemplateImage"
				/>
			</div>
			<h3>{title}</h3>
		</a>
	);
}
